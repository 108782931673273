import { alertNoCalendarEntrySelected, isPrototype } from './global-variables'
import { getTicketAmount } from './global-functions'
import { toggleFieldsets } from './toggle-fieldset'
import { initPopover } from './popover'
import { resetAmountInputs } from './amount-input'
import { sectionMenu } from './section-menu'
import { initModal } from './modal'

export const setFieldsDependingOnFantissimaTicketAmount = function () {
  const fantissimaSelectMenuAlert = document.querySelector('[data-js-menu-alert]')
  const fantissimaSelectMenuSection = document.querySelector('[data-js-select-menu]')
  const fantissimaSelectAdditionalOptions = document.querySelector('[data-js-select-additional-options]')
  const selectTicketAlert = document.querySelector('[data-js-ticket-alert]')
  const menuFormRow = document.querySelector('[data-js-menu-form]')

  if (window.ticketAmount === undefined) {
    window.fantissimaController = new AbortController()
    // execute only once
    const celebrationCheckboxes = fantissimaSelectMenuSection.querySelectorAll('input[id^="celebratecheckbox_"]')
    celebrationCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', function (event) {
        const currentBox = event.target
        if (currentBox.checked) {
          fantissimaSelectMenuSection.querySelector('[data-js-celebration-hint]').removeAttribute('hidden')
        }
      }, { signal: window.fantissimaController.signal })

      checkbox.addEventListener('click', function (event) {
        const currentBox = event.target
        // if this was previously checked
        if (currentBox.dataset.waschecked === 'true') {
          currentBox.checked = false
          currentBox.dataset.waschecked = 'false'
          fantissimaSelectMenuSection.querySelector('[data-js-celebration-hint]').setAttribute('hidden', 'hidden')
        } else {
          currentBox.dataset.waschecked = 'true'
        }

        // remove was checked from other radios
        celebrationCheckboxes.forEach(function (sibling) {
          if (sibling !== currentBox) {
            sibling.dataset.waschecked = 'false'
          }
        }, this)
      }, { signal: window.fantissimaController.signal })
    })
    window.ticketAmount = getTicketAmount()
  }

  if (getTicketAmount() > 0) {
    if (alertNoCalendarEntrySelected.hasAttribute('hidden')) {
      fantissimaSelectMenuSection.removeAttribute('hidden')
      selectTicketAlert.setAttribute('hidden', 'hidden')
    }

    const maxValueForAdditionalOptions = getTicketAmount()
    const inputFieldsAdditionalOptions = Array.from(fantissimaSelectAdditionalOptions.querySelectorAll('[data-js-amount-input] input'))

    inputFieldsAdditionalOptions.forEach((inputFieldAdditionalOptions) => {
      inputFieldAdditionalOptions.max = maxValueForAdditionalOptions

      if (inputFieldAdditionalOptions.value > maxValueForAdditionalOptions) {
        inputFieldAdditionalOptions.value = maxValueForAdditionalOptions
      }
    })
    initModal(fantissimaSelectMenuSection.querySelectorAll('[data-js-open-modal]'))
    checkMenuFormValidity()
  } else {
    fantissimaSelectMenuSection.setAttribute('hidden', 'hidden')
    fantissimaSelectAdditionalOptions.setAttribute('hidden', 'hidden')
    selectTicketAlert.removeAttribute('hidden')
    fantissimaSelectMenuAlert.removeAttribute('hidden')
    delete window.ticketAmount
    window.fantissimaController.abort()
    delete window.fantissimaController
  }

  const menuSelection = document.querySelector('[data-js-menu-per-person]')
  const menuSelectionContent = menuSelection.innerHTML
  const menuFieldset = menuFormRow.querySelectorAll('[data-js-menu-fieldset]')
  const fieldsetLength = Array.from(menuFieldset).length

  function addMenuSelection () {
    fantissimaSelectMenuAlert.removeAttribute('hidden')
    fantissimaSelectAdditionalOptions.setAttribute('hidden', 'hidden')
    const menuSelectionCount = fieldsetLength + 1
    let content = menuSelectionContent.replaceAll('§§id§§', String(menuSelectionCount))

    // replace index in form name attribute
    content = content.replaceAll(/(name=".*?\[.*?])(\[\d+?])/g,
      '$1[' + fieldsetLength + ']')

    menuFormRow.insertAdjacentHTML('beforeend', content)

    const newButton = menuFormRow.querySelector('#menuselect' + menuSelectionCount)
    sectionMenu(newButton)

    const menuSelect = menuFormRow.querySelector('[data-menu-select' + menuSelectionCount + ']')
    const realSelect = menuSelect.querySelector('select')
    const button = menuSelect.querySelector('button')
    menuSelect.querySelectorAll('input[type=radio]')
      .forEach((listItem) => {
        listItem.addEventListener('change', function (event) {
          const value = event.target.value
          const option = realSelect.querySelector('[value="' + value + '"]')
          button.querySelector('span').textContent = option.textContent
          option.selected = 'selected'
        })
      })
  }

  function removeMenuSelection () {
    menuFieldset[fieldsetLength - 1].remove()
  }

  function checkMenuFormValidity () {
    const menuSelects = menuFormRow.querySelectorAll('select')

    if (menuSelects.length > 0) {
      let isValid = true
      for (const i in menuSelects) {
        if (menuSelects[i].value === '') {
          isValid = false
          break
        }
      }

      const menuRequiredTextarea = menuFormRow.querySelectorAll('textarea[required]')
      for (const i in menuRequiredTextarea) {
        if (menuRequiredTextarea[i].value === '') {
          isValid = false
          break
        }
      }

      if (!fantissimaSelectMenuSection.hasAttribute('hidden') && isValid) {
        fantissimaSelectMenuAlert.setAttribute('hidden', 'hidden')
        fantissimaSelectAdditionalOptions.removeAttribute('hidden')

        const popoverButtons = fantissimaSelectAdditionalOptions.querySelectorAll('[data-js-open-popover]')
        if (popoverButtons) {
          initPopover(popoverButtons)
        }
      } else {
        fantissimaSelectMenuAlert.removeAttribute('hidden')
        fantissimaSelectAdditionalOptions.setAttribute('hidden', 'hidden')
      }

      if (isPrototype && isValid) {
        const addToCartButton = document.querySelector('[data-js-add-to-cart]')

        addToCartButton.addEventListener('click', function (event) {
          event.preventDefault()

          if (isPrototype) {
            document.querySelector('[data-js-add-to-cart-success]').showModal()
            resetAmountInputs()

            document.querySelector('[data-js-modal-close]').addEventListener('click', function () {
              this.closest('[data-js-add-to-cart-success]').close()
            })
          }
        })
      }
    }
  }

  if (getTicketAmount() > fieldsetLength) {
    addMenuSelection()
  } else if (fieldsetLength && getTicketAmount() < fieldsetLength) {
    removeMenuSelection()
  }

  toggleFieldsets()

  fantissimaSelectMenuSection.querySelectorAll('select, input, textarea').forEach((formField) => {
    formField.addEventListener('change', function () {
      checkMenuFormValidity()
    })
  })

  fantissimaSelectMenuSection.querySelectorAll('textarea').forEach((formField) => {
    formField.addEventListener('input', function () {
      checkMenuFormValidity()
    })
  })
}
