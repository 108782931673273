const modalCloseButton = document.querySelectorAll('[data-js-modal-close]')
modalCloseButton.forEach(button => {
  button.addEventListener('click', function () {
    this.closest('[data-js-modal]').close()
  })
})

export function initModal (modalButtons) {
  modalButtons.forEach(btn => {
    const btnControls = btn.getAttribute('aria-controls')
    const btnModal = document.querySelector('#' + btnControls)

    btn.addEventListener('click', function (event) {
      event.preventDefault()
      btnModal.showModal()

      document.addEventListener('click', function (event) {
        if (event.target.getAttribute('aria-controls') !== btnControls &&
            btnModal.open && !event.target.closest('.dialog__content')) {
          btnModal.close()
        }
      })
    })
  }, false)
}

const modalButtons = document.querySelectorAll('[data-js-open-modal]')
initModal(modalButtons)
